body {
  margin: 0;
  background-color: black;
  color:white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  background-color: black;
  color:white;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  padding: 10px;
}

.company-name {
  margin-left: 20px;
}

.nav-links {
  list-style: none;
  display: flex;
  margin-right: 20px;
}

.nav-links li {
  font-family: poppins;
  margin: 0 10px;
}

.nav-links li a {
  font-family: poppins;
  text-decoration: none;
  color: #333;
}

.nav-links li a:hover {
  color: #007bff;
  cursor: pointer;
}
